import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import ErrorPage, { ErrorPageData } from 'templates/ErrorPage/index';
import { SessionExpiryOption } from 'components/SessionExpiry/SessionExpiryWrapper';
import { usePageTracking } from 'helpers/pageTracking';
import {
  useResetTescoPageLoadTrackingOnRefresh,
  useTescoPageLoadTracking,
  useTescoPageViewTracking,
} from 'helpers/pageTrackingForTesco';

export const query = graphql`
  query {
    csErrorPage(error_id: { eq: "pet-maintenance" }) {
      ...ErrorPage
    }
  }
`;

const MaintenancePage: React.FC = () => {
  const data = useStaticQuery<ErrorPageData>(query);
  usePageTracking(data.csErrorPage.meta_title);
  useTescoPageLoadTracking();
  useResetTescoPageLoadTrackingOnRefresh();
  useTescoPageViewTracking();
  return (
    <ErrorPage data={data} sessionExpiryOption={SessionExpiryOption.NO_SESSION_EXPIRY} />
  );
};

export default MaintenancePage;
